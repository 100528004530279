import React from "react";
import "./Footer.css";
function Footer() {
  return (
    // footer content
    <div className="footer">
      <h6>©Copyrights - All Rights Reserved by @kareo 2023</h6>
    </div>
  );
}

export default Footer;
